<template>
    <div class="goods-list" v-loading="loading">
        <!-- 搜索关键字 -->
        <div class="search_bread" v-if="keyword">
            <router-link to="/">首页</router-link>
            <span class="padd-10">/</span>
            <span class="keyword">{{ keyword }}</span>
        </div>
        <div class="search_bread" v-else-if="!keyword && catewords">
            <router-link to="/">首页</router-link>
            <span class="padd-10">/</span>
            <router-link :to="{ path: '/list', query: { category_id: first_index, level: 1 } }">{{ catewords.split("/")[0] }}</router-link>
            <!-- <span class="keyword">{{ catewords.split('/')[0] }}</span> -->
            <span v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]" class="padd-10">/</span>
            <span v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]" class="keyword">{{ catewords.split("/")[1] }}</span>
        </div>
        <div class="list-wrap">
            <div class="goods-recommended" v-if="cargoList.length">
                <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
            </div>
            <div class="list-right">
                <!-- 排序筛选区 -->

                <div class="cargo-list" v-if="cargoList.length">
                    <div class="goods-info">
                        <div class="item" v-for="(item, index) in cargoList" :key="item.id">
                            <router-link :to="{ path: '/cacontrelist', query: { id: item.id, goods_arr: item.goods_arr } }">
                                <img class="img-wrap" :src="item.img" />
                                <span class="exchange" v-if="item.get_free_goods != null && item.get_free_goods.length > 0">换购产品</span>
                                <div class="goods-name">{{ item.label_name }}</div>
                                <div class="goods-name">{{ item.desc }}</div>
                            </router-link>
                        </div>
                    </div>
                    <div class="pager">
                        <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
                    </div>
                </div>
                <div class="empty" v-else>
                    <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "@/components/BreadCrumbs"
    import GoodsRecommend from "@/components/GoodsRecommend"
    import list from "./list"
    import { mapGetters } from "vuex"
    export default {
        name: "list",
        components: {
            BreadCrumbs,
            GoodsRecommend
        },
        data: () => {
            return {}
        },
        mixins: [list],
        created() {},
        computed: {
            ...mapGetters(["cartCount", "siteInfo", "member"]),
            logined: function () {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        },
        methods: {
            showPrice(data) {
                let price = data.discount_price
                if (data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price
                return price
            }
        }
    }
</script>

<style lang="scss" scoped>
    .padd-10 {
        padding: 0 10px;
    }
    .goods-list {
        background: #fff;
        padding-top: 10px;
    }

    .search_bread {
        display: inline-block;
        font-size: 14px;
        margin: 0px auto;
        width: 100%;
        padding: 10px;
        p {
            float: left;
        }
        li {
            float: left;
            padding: 0 10px;
        }
        .active a {
            color: #fff !important;
            border: 1px solid #ff547b !important;
            padding: 5px;
            border-radius: 5px;
            background: #ff547b !important;
        }
    }

    .selected_border {
        border: 1px solid $base-color;
    }

    .attr_filter {
        .el-tag {
            margin-right: 5px;
            margin-bottom: 10px;
            border-radius: 0;

            .title {
                color: $base-color;
                font-size: 15px;
            }
        }
    }

    .category {
        margin: 0 auto 10px auto;
        border: 1px solid #eeeeee;

        .brand {
            border-bottom: 1px solid #eeeeee;
            display: flex;
            flex-direction: row;

            &:last-child {
                border-bottom: none;
            }

            .table_head {
                width: 140px;
                min-width: 140px;
                border-right: 1px solid #eeeeee;
                padding-left: 10px;
                padding-top: 10px;
                background-color: #f2f6fc;
            }

            .table_body {
                flex-grow: 1;

                .initial {
                    margin: 5px auto 10px 10px;

                    span {
                        border: 0;
                        margin: 0;
                        padding: 5px 10px;
                        border: 0;
                        border-radius: 0;

                        &:hover {
                            background-color: $base-color;
                            color: #ffffff;
                        }
                    }
                }

                .content {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 10px;
                    width: 900px;

                    .brand-item {
                        margin-right: 10px;
                        margin-top: 10px;
                    }

                    .el-card {
                        width: 125px;
                        height: 45px;

                        &:hover {
                            border: 1px solid $base-color;
                            cursor: pointer;
                        }
                    }

                    span {
                        margin: auto 25px;
                    }
                }
            }

            .table_op {
                margin-top: 5px;
                margin-right: 5px;
            }

            .el-image {
                width: 100%;
                height: 100%;
            }
        }
    }

    .list-wrap {
        overflow: hidden;
    }

    .goods-recommended {
        width: 200px;
        background-color: #fff;
        float: left;
        margin-right: 10px;
    }

    .sort {
        display: flex;
        align-items: center;

        .item {
            display: flex;
            align-items: center;
            padding: 5px 15px;
            border: 1px solid #f1f1f1;
            border-left: none;
            cursor: pointer;

            &:hover {
                background: $base-color;
                color: #fff;
            }
        }

        .item-other {
            display: flex;
            align-items: center;
            padding: 5px 15px;
            border: 1px solid #f1f1f1;
            border-left: none;
            cursor: pointer;
        }

        .input-wrap {
            display: flex;
            align-items: center;

            .price_range {
                margin-left: 60px;
            }

            span {
                padding-left: 10px;
            }

            .el-input {
                width: 150px;
                margin-left: 10px;
            }

            .el-button {
                margin: 0 17px;
            }
        }

        > div:first-child {
            border-left: 1px solid #f1f1f1;
        }
    }

    .goods-info {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 220px;
            margin: 10px 20px 0 0;
            border: 1px solid #eeeeee;
            padding-top: 10px;
            position: relative;
            padding-bottom: 5px;
            .exchange {
                position: absolute;
                right: 0;
                top: 0;
                padding: 5px;
                background: #4caf50;
                color: #fff;
                border-radius: 5px;
            }
            &:nth-child(4n) {
                margin-right: initial !important;
            }

            &:hover {
                border: 1px solid $base-color;
            }

            .img-wrap {
                width: 198px;
                height: 198px;
                cursor: pointer;
                padding: 10px;
            }

            .goods-name {
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                word-break: break-all;
                cursor: pointer;

                &:hover {
                    color: $base-color;
                }
            }
            .goods_shux {
                color: #ccc;
                font-size: 12px;
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                word-break: break-all;
                cursor: pointer;
                width: 210px;
            }
            .price-wrap {
                padding: 0 10px;
                display: flex;
                align-items: center;

                .price {
                    display: flex;
                    color: $base-color;
                    font-size: $ns-font-size-lg;

                    p {
                        font-size: $ns-font-size-base;
                        display: flex;
                        align-items: flex-end;
                    }
                }

                .market-price {
                    color: #838383;
                    text-decoration: line-through;
                    margin-left: 10px;
                }
            }

            .sale-num {
                padding: 0 10px;
                display: flex;
                color: #838383;
                font-size: $ns-font-size-sm;

                p {
                    color: #4759a8;
                }
            }

            .shop_name {
                padding: 0 10px;
                display: flex;
                color: #838383;
            }

            .saling {
                padding: 0 10px;
                display: flex;
                font-size: $ns-font-size-sm;
                line-height: 1;

                .free-shipping {
                    background: $base-color;
                    color: #ffffff;
                    padding: 3px 4px;
                    border-radius: 2px;
                    margin-right: 5px;
                }

                .is_own {
                    color: #ffffff;
                    background: #ff850f;
                    border: 1px solid;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                    padding: 3px 4px;
                    border-radius: 2px;
                }

                .promotion-type {
                    color: $base-color;
                    border: 1px solid $base-color;
                    display: flex;
                    align-items: center;
                    padding: 1px 3px;
                }
            }

            .item-bottom {
                display: flex;
                margin-top: 5px;

                .collection {
                    flex: 1;
                    border: 1px solid #e9e9e9;
                    border-right: none;
                    border-left: none;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                .cart {
                    flex: 2;
                    border: 1px solid #e9e9e9;
                    border-right: none;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
    .price-icon-wrap {
        display: inline-block;
        max-width: 35px;
        margin-left: 3px;
        line-height: 1;
        margin-top: 4px;
        img {
            max-width: 100%;
        }
    }

    .empty {
        margin-top: 45px;
    }

    .pager {
        text-align: center;
        margin-top: 30px;
    }
</style>
